import React, { useState } from "react";
import CoursePopUp from "../Courses/CoursePopUp";
import ContactPopUp from "./ContactPopUp";
import { useNavigate } from "react-router-dom";

function Footer() {
  const [showPopUp, setShowPopUp] = useState(false);
  const handleShowCouses = (id) => {
    setShowPopUp(true);
  };
  const navigate = useNavigate()
  return (
    <div>
      {" "}
      <footer>
        <div class="container footer__container">
          <div class="footer__1">
            <a class="footer__logo">
              <h4>CoderWing</h4>
            </a>
            <p>
              <span className="CoderWingBold">CoderWing</span> is dedicated to
              helping you achieve your educational and career goals. Join us
              today and take the first step towards a brighter future!
            </p>
          </div>

          <div class="footer__2">
            <h4>Courses</h4>
            <ul class="permalinks">
              <li>
                <a onClick={handleShowCouses} className="FooterCors">
                  Become a React Developer
                </a>
              </li>
              <li>
                <a onClick={handleShowCouses} className="FooterCors">
                  Become a MERN Developer
                </a>
              </li>
              <li>
                <a onClick={handleShowCouses} className="FooterCors">
                  Become a Python Developer
                </a>
              </li>
              <li>
                <a onClick={handleShowCouses} className="FooterCors">
                  Become a Flutter Developer
                </a>
              </li>
              <li>
                <a onClick={handleShowCouses} className="FooterCors">
                  Become a Web Designer
                </a>
              </li>
            </ul>
          </div>

          <div class="footer__3">
            <h4>Privacy</h4>
            <ul class="privacy">
              <li>
                <a
                  className="FooterCors"
                  onClick={() => navigate("/Price&Policy")}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="FooterCors"
                  onClick={() => navigate("/Terms&Conditions")}
                >
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>

          <div class="footer__4">
            <h4>Contact Us</h4>
            <div>
              <div>
                <a href="tel:+9926640515" className="whiteColor">
                  9926640515
                </a>
              </div>
              <div>
                {" "}
                <a
                  href="mailto:inquiry.coderwing@gmail.com?subject=Your%20Subject&body=Your%20Message"
                  className="whiteColor"
                >
                  inquiry.coderwing@gmail.com
                </a>
              </div>
            </div>

            <ul class="footer__socials">
              <li>
                <a
                  className="FooterCors"
                  href="https://www.instagram.com/coderwing"
                >
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  className="FooterCors"
                  href="https://www.linkedin.com/company/coderwing/"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  className="FooterCors"
                  href="https://www.facebook.com/profile.php?id=61563504333014"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer__copyright">
          <small>
            Copyright &copy; <span className="CoderWingBold">CoderWing</span>{" "}
            Software
          </small>
        </div>
      </footer>
      {showPopUp && <ContactPopUp setShowPopUp={setShowPopUp} />}
    </div>
  );
}

export default Footer;
