import React from 'react'
import Header from './Home/Header'
import MainSlider from './Home/MainSlider';
import ServiceSection from './Home/ServiceSection ';
import Categories from './Home/Categories';
import Courses from './Home/Courses.jsx';
import Faq from './Home/Faq.jsx';
import ParallaxText from "./ParallaxText"
import { useRef, useEffect } from "react";
import OurProjects from './Home/OurProjects.jsx';
import Testimonials from './Home/Testimonials.jsx';
import SuccessStories from './Home/SuccessStories.jsx';
// import { Helmet } from 'react-helmet';

function Home() {
 const faqRef = useRef(null);

  useEffect(() => {
  
    faqRef.current = faqRef.current;
  }, []);

  const scrollToFAQ = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="page-wraper">
      {/* <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content="Online coding bootcamp , react.js , React.js , HTML and CSS course , ReactJS developer course , Full stack developer course , Web development bootcamp , UX/UI design course, Python data analysis course ,  free online courses"
        />
        <title>Home Page CoderWing</title>
        <link rel="canonical" href="https://educhampreact.betamxpertz.xyz/" />
      </Helmet> */}
      <Header scrollToFAQ={scrollToFAQ} />
      <MainSlider />
      <div>
        {" "}
        <ParallaxText baseVelocity={2}>
          <div className="scrollTickel">
            <p>
              <i className="fa fa-star starIcon " aria-hidden="true"></i>SKILL
              DEVELOPMENT
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>GROWTH
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>TRAINING
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>MERN
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>REACT
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>PYTHON
            </p>
          </div>
        </ParallaxText>
      </div>

      <Categories />
      <Courses />
      <OurProjects />
      <Testimonials />
      <SuccessStories />
      <div ref={faqRef}>
        <Faq />
      </div>
    </div>
  );
}

export default Home