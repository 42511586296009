import React, { useState, useRef, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import "../../assets/css/Courses.css";
import { useNavigate } from "react-router-dom";
import "../../../src/assets/css/contact.css";
import emailjs from "@emailjs/browser";

function ContactPopUp({ setShowPopUp }) {
  const form = useRef();
  const [closePopUp, setClosePopUp] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    interest: "",
    education: "",
    location: "",
  });
  const [ipAddress, setIPAddress] = useState("");
 
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);
  const handleForgotPasswordClose = (e) => {
    e.preventDefault();
    setClosePopUp(true);
    setShowPopUp(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.fullName) tempErrors.fullName = "Full Name is required";
    if (!formData.email) tempErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      tempErrors.email = "Email is invalid";
    if (!formData.phone) tempErrors.phone = "Phone number is required";
    if (formData.phone.length !== 10)
      tempErrors.phone = "Phone number must be 10 digits";
    if (!formData.interest) tempErrors.interest = "Interest is required";
    if (!formData.education)
      tempErrors.education = "Current Education is required";
    if (!formData.location)
      tempErrors.location = "Current Location is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
    
      const templatedParam = {
        to_name: "CoderWing",
        from_name: formData.fullName,
        message: `
        Full Name:  ${formData.fullName}
        Email:  ${formData.email}
        Phone:  ${formData.phone}
        Interest:  ${formData.interest}
        Education:  ${formData.education}
        Location: ${formData.location}
      
      `,
      };
      console.log(templatedParam);
      emailjs
        .send("service_i9krm8l", "template_d0nujwn", templatedParam, {
          publicKey: "8z_Ce6_tIKl-PtAkn",
        })
        .then(
          (result) => {
            // console.log("Email successfully sent to CoderWing!", result.text);
            console.log("Email successfully sent to CoderWing!", result.text);
            alert("Your message has been sent!");
            setShowPopUp(false); // Optionally close the popup
          },
          (error) => {
            console.log("Failed to send email", error.text);
            alert("Failed to send your message, please try again.");
          }
        );
    }
  };

  console.log(ipAddress);

  return (
    <div>
      <div
        className={`popup-backdrop contactPhnView ${
          closePopUp ? "closepopUp" : "openpopUp"
        }`}
      >
        <div className="ContactUspp">
          <h1 className="boldHding">Get In Touch</h1>
          <div className="innerPopupBox">
            <form ref={form} onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && (
                  <span className="error">{errors.fullName}</span>
                )}
              </div>

              <div className="form-group">
                <label>Email address</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>

              <div className="form-group">
                <label>Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>

              <div className="form-group">
                <label>Interested In</label>
                <select
                  className="custom-select"
                  name="interest"
                  value={formData.interest}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Open this select menu
                  </option>
                  <option value="MERN">MERN{"(Developer Course)"}</option>
                  <option value="React.js">
                    React.js{"(Developer Course)"}
                  </option>
                  <option value="Python">Python{"(Developer Course)"}</option>
                </select>
                {errors.interest && (
                  <span className="error">{errors.interest}</span>
                )}
              </div>

              <div className="form-group">
                <label>Current Education</label>
                <input
                  type="text"
                  className="form-control"
                  name="education"
                  value={formData.education}
                  onChange={handleChange}
                />
                {errors.education && (
                  <span className="error">{errors.education}</span>
                )}
              </div>

              <div className="form-group">
                <label>Current Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                />
                {errors.location && (
                  <span className="error">{errors.location}</span>
                )}
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginTop: "10px" }}
              >
                Submit
              </button>
            </form>
          </div>

          <button className="cross" onClick={handleForgotPasswordClose}>
            <RxCross2 />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactPopUp;

// import React, { useState } from "react";
// import { RxCross2 } from "react-icons/rx";
// import "../../assets/css/Courses.css";
// import courseImg from "../../assets/images/CourseIMG.jpg";
// import { TiTickOutline } from "react-icons/ti";
// import { useNavigate } from "react-router-dom";
// import "../../../src/assets/css/contact.css";

// function ContactPopUp({ setShowPopUp }) {
//   const [closePopUp, setClosePopUp] = useState(false);
//   const navigate = useNavigate();

//   const handleForgotPasswordClose = (e) => {
//     e.preventDefault();
//     setClosePopUp(true);
//     setShowPopUp(false);
//   };

//   return (
//     <div>
//       <div
//         className={`popup-backdrop ${closePopUp ? "closepopUp" : "openpopUp"}`}
//       >
//         <div className="ContactUspp">
//           <h1 className="boldHding">Get In Touch</h1>
//           <div className="innerPopupBox">
//             <form>
//               <div className="form-group">
//                 <label>Full Name</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   aria-describedby="emailHelp"
//                 />
//               </div>

//               <div className="form-group">
//                 <label>Email address</label>
//                 <input
//                   type="email"
//                   className="form-control"
//                   aria-describedby="emailHelp"
//                 />
//               </div>

//               <div className="form-group">
//                 <label>Contact Number</label>
//                 <input type="text" className="form-control" />
//               </div>

//               <div className="form-group">
//                 <label>Interested In</label>
//                 <select className="custom-select" defaultValue="">
//                   <option value="" disabled>
//                     Open this select menu
//                   </option>
//                   <option value="1">React.js</option>
//                   <option value="2">MERN</option>
//                   <option value="3">Python</option>
//                 </select>
//               </div>

//               <div className="form-group">
//                 <label>Current Education</label>
//                 <input type="text" className="form-control" />
//               </div>

//               <div className="form-group">
//                 <label>Current Location</label>
//                 <input type="text" className="form-control" />
//               </div>

//               <button
//                 type="submit"
//                 className="btn btn-primary"
//                 style={{ marginTop: "10px" }}
//               >
//                 Submit
//               </button>
//             </form>
//           </div>

//           <button className="cross" onClick={handleForgotPasswordClose}>
//             <RxCross2 />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ContactPopUp;
